<template>
  <div class="container-fluid">
    <div v-for="(award, ind) in awards" :key="ind" class="row py-3">
      <div class="col-12 text-center">
        <div class="wrapper-award">
          <img :src="bg" alt="Fonde de los premios de los equipos" class="img-fluid">
          <div class="award-detail">
            <div class="title">
              <h4 class="border-text-black">{{ $t('champion') }}</h4>
              <img :src="teamImage" alt="Equipo" class="img-fluid">
            </div>
            <div class="detail">
              <h5 class="border-text-black">{{ award.division_name }}</h5>
              <h6 class="border-text-black">{{ award.season_name }}</h6>
              <p class="border-text-black mb-0">{{ award.date }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      awards: [],
      teamImage: '',
      bg: ''
    }
  },
  mounted() {
    this.$store.dispatch('SET_MENU_PRIMARY_TEAM', '')
    this.fetchData()
  },
  methods: {
    fetchData() {
      const lang = this.$route.params.lang
      const console = this.$route.params.console
      const slug = this.$route.params.teamSlug
      this.path = `${lang}/console/${console}/team/${slug}/awards`
      this.$axios.get(this.path).then((response) => {
        const data = response.data
        this.awards = data.awards
        this.teamImage = data.team_image
        this.bg = data.bg
      })
    }
  }
}
</script>
